/* push footer to the bottom */
html, body, #main, #wrap {
    height: 100%;
    margin: 0;
}
#ci-content {
    padding: 6em 0 3em 0;
    min-height: -webkit-calc(100% - 50px);     /* Chrome */
    min-height: -moz-calc(100% - 50px);     /* Firefox */
    min-height: calc(100% - 50px);     /* native */
}
footer {
    position: relative;
    clear: both;
    height: 50px;
}

/* I'm not sure why, but items in dropdown have ~0 padding using default styling */
.breadcrumb .ui.dropdown .menu>.item {
  padding: 20px!important;
}
/* Convince dropdowns to open aligned to the right */
.breadcrumb .ui.dropdown .menu {
  left: auto;
  right: 0px;
}

/* https://github.com/FortAwesome/Font-Awesome/issues/671#issuecomment-513797835 */
.ui.icon {
  filter: blur(0);
}

iframe.log {
  width: 100%;
  height: 50vh;
  border: none;
  padding: 0px;
  background-color: rgba(0,0,0,0.05)
}

/* Fix composite icons in feed labels */
.ui.feed > .event > .label > .icons > .icon {
  padding: unset !important;
}
.ui.feed > .event > .label > .icons > .icon.small {
  font-size: 1.125em !important;
}

div.log {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  font-family: monospace;
}

div.log div.line {
  text-indent: -10ex;
  margin-left: 10ex;
  transition: all 0.1s ease;
  min-height: 2ex;
}
div.log div.placeholder div.line {
  margin-left: 0;
}
div.log div.line.log-collapsed {
  line-height: 0px;
  min-height: 0px;
  visibility: hidden;
  overflow: hidden;
}

div.log span.time {
  color: #aaa;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sticky-controls {
  position: sticky !important;
  position: -webkit-sticky;
  top: -1em;
  float: right;
  background-color: rgba(255,255,255,0.85);
}
.sticky-controls.ui.segments {
  /* reduce border for semi-transparent glassy look */
  border: 1px solid rgba(30,50,40,0.05) !important;
  /* room for drop shadow */
  margin-right: .285714rem;
}

.fg-black { color: #000; }
.bg-black { background-color: #000; }
.fg-red { color: #a00; }
.bg-red { background-color: #faa; }
.fg-green { color: #0a0; }
.bg-green { background-color: #afa; }
.fg-yellow { color: #b90; }
.bg-yellow { background-color: #ff7; }
.fg-blue { color: #00a; }
.bg-blue { background-color: #aaf; }
.fg-magenta { color: #a0a; }
.bg-magenta { background-color: #faf; }
.fg-cyan { color: #0aa; }
.bg-cyan { background-color: #aff; }
.fg-white { color: #777; }
.bg-white { background-color: #fff; }

/* bright */
.fg-b-black { color: #777; }
.bg-b-black { background-color: #333; }
.fg-b-red { color: #f00; }
.bg-b-red { background-color: #fcc; }
.fg-b-green { color: #0f0; }
.bg-b-green { background-color: #cfc; }
.fg-b-yellow { color: #fc0; }
.bg-b-yellow { background-color: #ffb; }
.fg-b-blue { color: #00f; }
.bg-b-blue { background-color: #ccf; }
.fg-b-magenta { color: #f0f; }
.bg-b-magenta { background-color: #fcf; }
.fg-b-cyan { color: #0ff; }
.bg-b-cyan { background-color: #cff; }
.fg-b-white { color: #999; }
.bg-b-white { background-color: #fff; }


.bold { font-weight: bold; }
.faint { font-weight: lighter; }
