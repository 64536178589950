html, body, #main, #wrap {
  height: 100%;
  margin: 0;
}

#ci-content {
  min-height: -webkit-calc(100% - 50px);
  min-height: -moz-calc(100% - 50px);
  min-height: calc(100% - 50px);
  padding: 6em 0 3em;
}

footer {
  clear: both;
  height: 50px;
  position: relative;
}

.breadcrumb .ui.dropdown .menu > .item {
  padding: 20px !important;
}

.breadcrumb .ui.dropdown .menu {
  left: auto;
  right: 0;
}

.ui.icon {
  filter: blur();
}

iframe.log {
  background-color: #0000000d;
  border: none;
  width: 100%;
  height: 50vh;
  padding: 0;
}

.ui.feed > .event > .label > .icons > .icon {
  padding: unset !important;
}

.ui.feed > .event > .label > .icons > .icon.small {
  font-size: 1.125em !important;
}

div.log {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  font-family: monospace;
}

div.log div.line {
  text-indent: -10ex;
  min-height: 2ex;
  margin-left: 10ex;
  transition: all .1s;
}

div.log div.placeholder div.line {
  margin-left: 0;
}

div.log div.line.log-collapsed {
  visibility: hidden;
  min-height: 0;
  line-height: 0;
  overflow: hidden;
}

div.log span.time {
  color: #aaa;
  -webkit-user-select: none;
  user-select: none;
}

.sticky-controls {
  float: right;
  background-color: #ffffffd9;
  position: -webkit-sticky;
  top: -1em;
  position: sticky !important;
}

.sticky-controls.ui.segments {
  margin-right: .285714rem;
  border: 1px solid #1e32280d !important;
}

.fg-black {
  color: #000;
}

.bg-black {
  background-color: #000;
}

.fg-red {
  color: #a00;
}

.bg-red {
  background-color: #faa;
}

.fg-green {
  color: #0a0;
}

.bg-green {
  background-color: #afa;
}

.fg-yellow {
  color: #b90;
}

.bg-yellow {
  background-color: #ff7;
}

.fg-blue {
  color: #00a;
}

.bg-blue {
  background-color: #aaf;
}

.fg-magenta {
  color: #a0a;
}

.bg-magenta {
  background-color: #faf;
}

.fg-cyan {
  color: #0aa;
}

.bg-cyan {
  background-color: #aff;
}

.fg-white {
  color: #777;
}

.bg-white {
  background-color: #fff;
}

.fg-b-black {
  color: #777;
}

.bg-b-black {
  background-color: #333;
}

.fg-b-red {
  color: red;
}

.bg-b-red {
  background-color: #fcc;
}

.fg-b-green {
  color: #0f0;
}

.bg-b-green {
  background-color: #cfc;
}

.fg-b-yellow {
  color: #fc0;
}

.bg-b-yellow {
  background-color: #ffb;
}

.fg-b-blue {
  color: #00f;
}

.bg-b-blue {
  background-color: #ccf;
}

.fg-b-magenta {
  color: #f0f;
}

.bg-b-magenta {
  background-color: #fcf;
}

.fg-b-cyan {
  color: #0ff;
}

.bg-b-cyan {
  background-color: #cff;
}

.fg-b-white {
  color: #999;
}

.bg-b-white {
  background-color: #fff;
}

.bold {
  font-weight: bold;
}

.faint {
  font-weight: lighter;
}

/*# sourceMappingURL=index.47737298.css.map */
